// Messages (ar)

const config = {
  code: 'ar',
  label: 'عربي',
  label_en: 'Arabic',
  rtl: true,
};

const messages = {
  // Navbar
  navbar_title: 'إلغاء الاشتراك',

  // Unsubscribe page
  unsubpage_title: 'هل أنت متأكد أنك تريد إلغاء الاشتراك؟',
  unsubpage_msg1:
    'اذا كنت تريد إيقاف الرسائل التسويقية المرسلة من قبلنا، يرجى تأكيد ذلك عن طريق الضغط على زر إلغاء الإشتراك في الأسفل',
  unsubpage_unsubscribe: 'إلغاء الاشتراك',
  unsubpage_unsubscribing: 'جارٍ إلغاء الاشتراك ...',
  unsubpage_result_success: 'تم إلغاء اشتراكك',
  unsubpage_result_error: 'فشل إلغاء الاشتراك',

  // Error page
  errorpage_title: 'خطأ',
  errorpage_msg1: 'رابط إلغاء الاشتراك هذا غير صالح.',
  errorpage_msg2: 'لم نتمكن من المضي قدمًا.',
};

const lang = { config, messages };

export default lang;
