// Unsubscription API

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const BASE_URL = process.env.REACT_APP_MS_URL;
const CLIENT_ID = process.env.REACT_APP_MS_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_MS_CLIENT_SECRET;
const X_CHANNEL = 'WEBSITE';

const TIMEOUT = 15 * 1000;
const TIMEOUT_CODES = ['ESOCKETTIMEDOUT', 'ETIMEDOUT', 'ECONNABORTED'];

const HTTP_STATUS_OK = [200, 201];

function handleResponse(response) {
  const { status: httpStatus, data } = response || {};
  const { info, errors = null } = data || {};
  const success = HTTP_STATUS_OK.includes(httpStatus) && info === 'SUCCESS';
  return { success, info, errors };
}

function handleError(error) {
  if (TIMEOUT_CODES.includes(error.code)) {
    return { success: false, timeout: true };
  }
  if (error.response) {
    return handleResponse(error.response);
  }
  return { success: false };
}

export async function unsubscribe(token) {
  const url = `${BASE_URL}/v1/api/v1/consumers/sms-unsubscribe`;
  const correlationId = uuidv4();
  const body = { token };

  const options = {
    timeout: TIMEOUT,
    headers: {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      'X-Channel': X_CHANNEL,
      'X-Correlation-Id': correlationId,
    },
  };

  return axios
    .post(url, body, options)
    .then(handleResponse)
    .catch(handleError)
    .then((result) => ({ ...result, correlationId }));
}
