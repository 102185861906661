import React from 'react';
import { Outlet } from 'react-router-dom';

import SessionContextProvider from '../../../shared/SessionContext';
import Header from './Header';

function Layout({ market, l10n }) {
  return (
    <SessionContextProvider market={market} l10n={l10n}>
      <Header />
      <Outlet />
    </SessionContextProvider>
  );
}

export default Layout;
