import React, { useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { useSession } from '../../../shared/SessionContext';
import { useUnsubscribeAPI } from '../../../shared/useUnsubscribeAPI';

function UnsubscribeResult({ success, children }) {
  const style = { color: success ? 'green' : 'red' };
  return <Card.Text style={style}>{children}</Card.Text>;
}

function UnsubscribeButton({ loading, onClick, children }) {
  return (
    <Button
      className="mx-2"
      variant="primary"
      disabled={loading}
      onClick={onClick}
    >
      {loading && (
        <Spinner
          className="mx-2"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </Button>
  );
}

function UnsubscribePage() {
  const { M } = useSession();
  const { loading, hasResponse, success, canRetry, onUnsubscribe } =
    useUnsubscribeAPI();

  const showResult = hasResponse;
  const showButton = !hasResponse || (!success && canRetry);

  const messages = useMemo(() => {
    return [M.unsubpage_msg1, M.unsubpage_msg2, M.unsubpage_msg3].filter(
      (msg) => !!msg
    );
  }, [M]);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ textAlign: 'center', marginTop: 75 }}
    >
      <Card className="w-75" style={{ maxWidth: 640 }}>
        <Card.Body>
          <Card.Title className="mt-3 mb-4">{M.unsubpage_title}</Card.Title>
          {messages.map((message, index) => (
            <Card.Text key={index}>{message}</Card.Text>
          ))}
          <div className="mt-4 mb-4">
            {showResult && (
              <UnsubscribeResult success={success}>
                <strong>
                  {success
                    ? M.unsubpage_result_success
                    : M.unsubpage_result_error}
                </strong>
              </UnsubscribeResult>
            )}
            {showButton && (
              <UnsubscribeButton loading={loading} onClick={onUnsubscribe}>
                {loading ? M.unsubpage_unsubscribing : M.unsubpage_unsubscribe}
              </UnsubscribeButton>
            )}
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default UnsubscribePage;
