import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import * as API from './unsubApi';

const MAX_RETRIES = 3;

export function useUnsubscribeAPI() {
  const [qs] = useSearchParams();
  const token = qs.get('token');

  const [state, setState] = useState({
    loading: false,
    response: null,
    error: null,
    count: 0,
  });

  const onUnsubscribe = useCallback(() => {
    Promise.resolve()
      .then(() => {
        setState((prev) => ({ loading: true, count: prev.count + 1 }));
      })
      .then(() => API.unsubscribe(encodeURIComponent(token)))
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false, response }));
      })
      .catch((error) => {
        setState((prev) => ({ ...prev, loading: false, error }));
      });
  }, [token]);

  return useMemo(() => {
    const { loading, response, error, count } = state;
    const { success, timeout } = response || {};
    const { message } = error || {};

    const memo = {
      loading,
      hasResponse: !!response || !!error,
      success: !!success,
      timeout: !!timeout,
      error: message || null,
      canRetry: count < MAX_RETRIES,
      onUnsubscribe,
    };

    return memo;
  }, [state, onUnsubscribe]);
}
