// Localization config (Ukraine)

import { Localization } from '../../../shared/Localization';

import ua from '../../generic/l10n/messages_ua';
import en from '../../generic/l10n/messages_en';

const localization = new Localization('default_ua', ua, [ua, en]);

export default localization;
