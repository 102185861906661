export class Localization {
  constructor(key, langDefault, languages) {
    this.key = key;
    this.langDefault = langDefault;
    this.langArr = languages;
    this.langMap = this.langArr.reduce(
      (result, lang) => Object.assign(result, { [lang.config.code]: lang }),
      {}
    );
    this.langList = this.langArr.map((lang) => lang.config);
  }

  getKey() {
    return this.key;
  }

  isValid(lang) {
    return !!this.langMap[lang];
  }

  getLanguages() {
    return this.langList;
  }

  getDefault(preferredLangs) {
    if (Array.isArray(preferredLangs)) {
      const lang = preferredLangs.find((lang) => this.isValid(lang));
      if (lang) return lang;
    }
    return this.langDefault.config.code;
  }

  getLanguage(lang) {
    return this.langMap[lang] || this.langDefault;
  }

  getConfig(lang) {
    return this.getLanguage(lang).config;
  }

  getMessages(lang) {
    return this.getLanguage(lang).messages;
  }
}
