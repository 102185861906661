import React, { useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import { useSession } from '../../../shared/SessionContext';

function ErrorPage() {
  const { M } = useSession();

  const messages = useMemo(() => {
    return [M.errorpage_msg1, M.errorpage_msg2, M.errorpage_msg3].filter(
      (msg) => !!msg
    );
  }, [M]);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ textAlign: 'center', marginTop: 75 }}
    >
      <Card className="w-75" style={{ maxWidth: 640 }}>
        <Card.Body>
          <Card.Title className="mt-3 mb-4">{M.errorpage_title}</Card.Title>
          {messages.map((message, index) => (
            <Card.Text key={index}>{message}</Card.Text>
          ))}
          <div className="mt-5"></div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ErrorPage;
