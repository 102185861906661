import { createContext, useContext } from 'react';
import { useMessages } from './useMessages';

const SessionContext = createContext();

function SessionContextProvider(props) {
  const { market, l10n } = props;
  const { M, language, languages, setLanguage } = useMessages(l10n);

  const value = { market, M, language, languages, setLanguage };

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  );
}

export const useSession = () => useContext(SessionContext);

export default SessionContextProvider;
