// Messages (en)

const config = {
  code: 'en',
  label: 'English',
  label_en: 'English',
  rtl: false,
};

const messages = {
  // Navbar
  navbar_title: 'Unsubscribe',

  // Unsubscribe page
  unsubpage_title: 'Are you sure you want to unsubscribe?',
  unsubpage_msg1:
    'If you no longer want to receive marketing communication from us, please click the unsubscription button below to confirm.',
  unsubpage_unsubscribe: 'Unsubscribe',
  unsubpage_unsubscribing: 'Unsubscribing...',
  unsubpage_result_success: 'You have been unsubscribed.',
  unsubpage_result_error: 'Unsubscription failed.',

  // Error page
  errorpage_title: 'Oops!',
  errorpage_msg1: 'This unsubscription link is invalid.',
  errorpage_msg2: 'We were not able to proceed.',
};

const lang = { config, messages };

export default lang;
