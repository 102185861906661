// Localization config (Palestine)

import { Localization } from '../../../shared/Localization';

import ar from '../../generic/l10n/messages_ar';
import en from '../../generic/l10n/messages_en';

const localization = new Localization('default_ps', ar, [ar, en]);

export default localization;
