// Messages (id)

const config = {
  code: 'id',
  label: 'Bahasa Indonesia',
  label_en: 'Indonesian',
  rtl: false,
};

const messages = {
  // Navbar
  navbar_title: 'Berhenti berlangganan',

  // Unsubscribe page
  unsubpage_title: 'Apakah Anda yakin ingin berhenti berlangganan?',
  unsubpage_msg1:
    'Jika Anda tidak lagi ingin menerima komunikasi pemasaran dari kami, silakan klik tombol berhenti berlangganan di bawah ini untuk mengonfirmasi.',
  unsubpage_unsubscribe: 'Berhenti berlangganan',
  unsubpage_unsubscribing: 'Berhenti berlangganan...',
  unsubpage_result_success: 'Anda telah berhenti berlangganan.',
  unsubpage_result_error: 'Berhenti berlangganan gagal.',

  // Error page
  errorpage_title: 'Oops!',
  errorpage_msg1: 'Tautan berhenti berlangganan ini tidak valid.',
  errorpage_msg2: 'Kami tidak dapat melanjutkan.',
};

const lang = { config, messages };

export default lang;
