import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { APPS } from './apps';

function App() {
  const { hostname: site } = window.location;
  const AppComponent = APPS[site] || APPS.DEFAULT;
  return <AppComponent />;
}

function WrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default WrappedApp;
