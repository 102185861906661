// Localization config (Iraq)

import { Localization } from '../../../shared/Localization';

import ar from '../../generic/l10n/messages_ar';
import ku from '../../generic/l10n/messages_ku';
import en from '../../generic/l10n/messages_en';

const localization = new Localization('default_iq', ar, [ar, ku, en]);

export default localization;
