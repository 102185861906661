import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CTA_TO_PATH = {
  unsubscribe: 'unsubscribe',
};

function NavigateCTA() {
  const navigate = useNavigate();
  const [qs] = useSearchParams();

  useEffect(() => {
    const cta = qs.get('sms_cta');
    const pathname = CTA_TO_PATH[cta];
    if (pathname) {
      navigate({ pathname, search: `?${qs}` });
    } else {
      navigate('error');
    }
  }, [navigate, qs]);

  return null;
}

export default NavigateCTA;
