import Dropdown from 'react-bootstrap/Dropdown';

import { useSession } from './SessionContext';

function LanguageDropdown() {
  const { language, languages, setLanguage } = useSession();

  const menuAlign = language.rtl ? 'start' : 'end';

  return (
    <Dropdown onSelect={setLanguage} align={menuAlign}>
      <Dropdown.Toggle variant="secondary-light">
        <span className="mx-2 bi bi-globe"></span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {languages.map((lang) => (
          <Dropdown.Item key={lang.code} eventKey={lang.code}>
            {lang.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageDropdown;
