// Localization config (Indonesia)

import { Localization } from '../../../shared/Localization';

import id from '../../generic/l10n/messages_id';
import en from '../../generic/l10n/messages_en';

const localization = new Localization('default_id', id, [id, en]);

export default localization;
