import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import { useSession } from '../../../shared/SessionContext';
import { usePageTitle } from '../../../shared/usePageTitle';
import LanguageDropdown from '../../../shared/LanguageDropdown';

function Header() {
  const { M } = useSession();
  usePageTitle(M.navbar_title);

  return (
    <Navbar bg="light" variant="light">
      <Container>
        <Navbar.Brand>{M.navbar_title}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <LanguageDropdown />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
