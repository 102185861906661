// Messages (ua)

const config = {
  code: 'uk',
  label: 'Українська',
  label_en: 'Ukrainian',
  rtl: false,
};

const messages = {
  // Navbar
  navbar_title: 'Сторінка відписки',

  // Unsubscribe page
  unsubpage_title:
    'Ви впевнені, що хочете відписатись від комунікації компанії Філіп Морріс?',
  unsubpage_msg1:
    'Якщо Ви більше не хочете отримувати комунікацію від нас, натисніть кнопку "Відписатись".',
  unsubpage_unsubscribe: 'Відписатись',
  unsubpage_unsubscribing: 'Обробляємо запит на відписку...',
  unsubpage_result_success: 'Ви відписались від комунікації.',
  unsubpage_result_error: 'Не вдалось обробити відписку.',

  // Error page
  errorpage_title: 'Упс, щось пішло не так!',
  errorpage_msg1: 'Цей лінк відписки не існує.',
  errorpage_msg2: 'Нам не вдалось обробити запит на відписку.',
};

const lang = { config, messages };

export default lang;
