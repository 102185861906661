import React from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import { useSession } from '../../../shared/SessionContext';

function ErrorPage() {
  const { M } = useSession();

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ textAlign: 'center', marginTop: 75 }}
    >
      <Card className="w-75" style={{ maxWidth: 640 }}>
        <Card.Body>
          <Card.Title className="mb-4">{M.errorpage_title}</Card.Title>
          <Card.Text>{M.errorpage_msg1}</Card.Text>
          <Card.Text>{M.errorpage_msg2}</Card.Text>
          <div className="mt-5"></div>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ErrorPage;
