// Messages (ku)

const config = {
  code: 'ku',
  label: 'کوردی',
  label_en: 'Kurdish',
  rtl: true,
};

const messages = {
  // Navbar
  navbar_title: 'وازهێنان لە بەشداریکردن',

  // Unsubscribe page
  unsubpage_title: 'دڵنیای کە دەتەوێت بەشداریکردنەکەت هەڵبوەشێنیتەوە؟',
  unsubpage_msg1:
    'ئەگەر چیتر ناتەوێت پەیوەندی مارکێتینگ لە ئێمەوە وەربگریت، تکایە کرتە لەسەر دوگمەی بێ بەشداریکردن لە خوارەوە بکە بۆ پشتڕاستکردنەوەی',
  unsubpage_unsubscribe: 'بەشداریکردن هەڵوەشێنەرەوە',
  unsubpage_unsubscribing: 'هەڵوەشاندنەوەی بەشداریکردن...',
  unsubpage_result_success: 'بەشداریکردنت هەڵوەشاوەتەوە',
  unsubpage_result_error: 'هەڵوەشاندنەوەی بەشداریکردن شکستی هێنا',

  // Error page
  errorpage_title: 'خطأ',
  errorpage_msg1: 'ئەم بەستەرەی هەڵوەشاندنەوەی بەشداریکردن نادروستە.',
  errorpage_msg2: 'ئێمە نەمانتوانی بەردەوام بین.',
};

const lang = { config, messages };

export default lang;
