import { useCallback, useMemo, useState } from 'react';

const getLangKey = (l10n) => {
  return `pmi_l10n_${l10n.getKey()}`;
};

const loadLang = (l10n) => {
  const key = getLangKey(l10n);
  return localStorage.getItem(key);
};

const saveLang = (l10n, value) => {
  const key = getLangKey(l10n);
  localStorage.setItem(key, value);
};

export function useMessages(l10n) {
  // get preferred language codes
  const prefs = useMemo(() => {
    const langs = (navigator && navigator.languages) || [];
    const codes = langs.map((lang) => lang.split('-')[0]);
    return codes;
  }, []);

  // initial lang state
  const langInitial = useMemo(() => {
    const lang = loadLang(l10n);
    const langChecked = l10n.isValid(lang) ? lang : l10n.getDefault(prefs);
    if (lang !== langChecked) saveLang(l10n, langChecked);
    return langChecked;
  }, [l10n, prefs]);

  // create lang state
  const [lang, setLang] = useState(langInitial);

  // update (and save) lang state
  const setLanguage = useCallback(
    (lang) => {
      const langChecked = l10n.isValid(lang) ? lang : l10n.getDefault(prefs);
      saveLang(l10n, langChecked);
      setLang(langChecked);
    },
    [l10n, prefs, setLang]
  );

  const result = useMemo(() => {
    const M = l10n.getMessages(lang);
    const languages = l10n.getLanguages();

    // Update document's display direction
    const language = l10n.getConfig(lang);
    document.body.dir = language.rtl ? 'rtl' : 'ltr';

    return { M, language, languages, setLanguage };
  }, [l10n, lang, setLanguage]);

  return result;
}
