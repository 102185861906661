// Messages (en)

const config = {
  code: 'en',
  label: 'English',
  label_en: 'English',
  rtl: false,
};

const messages = {
  // Navbar
  navbar_title: 'Unsubscribe',

  // Error page
  errorpage_title: 'Oops!',
  errorpage_msg1: 'This unsubscription link is invalid.',
  errorpage_msg2: 'We were not able to proceed.',
};

const lang = { config, messages };

export default lang;
