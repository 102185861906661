import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NavCTA from '../shared/NavigateCTA';
import NavError from '../shared/NavigateError';

import LayoutUNK from '../default/unk/components/Layout';
import ErrorUNK from '../default/unk/components/ErrorPage';

import Layout from '../default/generic/components/Layout';
import Error from '../default/generic/components/ErrorPage';
import Unsub from '../default/generic/components/UnsubscribePage';

import l10nPS from '../default/ps/l10n';
import l10nIQ from '../default/iq/l10n';
import l10nID from '../default/id/l10n';
import l10nUA from '../default/ua/l10n';

function DefaultApp() {
  return (
    <Routes>
      <Route path="/" element={<LayoutUNK />}>
        <Route index element={<NavError />} />
        <Route path="*" element={<NavError />} />
        <Route path="error" element={<ErrorUNK />} />
      </Route>
      <Route path="ps" element={<Layout market="ps" l10n={l10nPS} />}>
        <Route index element={<NavCTA />} />
        <Route path="*" element={<NavError />} />
        <Route path="error" element={<Error />} />
        <Route path="unsubscribe" element={<Unsub />} />
      </Route>
      <Route path="iq" element={<Layout market="iq" l10n={l10nIQ} />}>
        <Route index element={<NavCTA />} />
        <Route path="*" element={<NavError />} />
        <Route path="error" element={<Error />} />
        <Route path="unsubscribe" element={<Unsub />} />
      </Route>
      <Route path="id" element={<Layout market="id" l10n={l10nID} />}>
        <Route index element={<NavCTA />} />
        <Route path="*" element={<NavError />} />
        <Route path="error" element={<Error />} />
        <Route path="unsubscribe" element={<Unsub />} />
      </Route>
      <Route path="ua" element={<Layout market="ua" l10n={l10nUA} />}>
        <Route index element={<NavCTA />} />
        <Route path="*" element={<NavError />} />
        <Route path="error" element={<Error />} />
        <Route path="unsubscribe" element={<Unsub />} />
      </Route>
    </Routes>
  );
}

export default DefaultApp;
